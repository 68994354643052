import { Container, Section } from '@components/common'
import Button from '@mrs-ui/Button'
import { Link } from 'gatsby'
import React from 'react'

const Positions = () => {
  return (
    <Section>
      <Container>
        <div className="md:w-3/5 mx-auto">
          <h2 className="text-center">Open positions</h2>
          <p>Sorry, we currently have no open positions!</p>
          <p>
            However,{' '}
            <b>we're always open to discussing potential opportunities </b> if you
            think you you'd be a good fit at MRS.
          </p>
          <p>
            If you consider yourself a <b>highly capable:</b>
          </p>
          <ul>
            <li>cloud solutions architect</li>
            <li>data warehouse architect</li>
            <li>BI developer</li>
            <li>software developer/engineer</li>
            <li>SharePoint developer</li>
            <li>Microsoft MVP</li>
          </ul>
          <p>
            ... we encourage you to <Link to="/contact">contact us today</Link>!
            Send us your resume and LinkedIn profile, tell us what you're
            interested in and we'll let you know if we think there's a fit.
          </p>
          <Button className="mt-4" appearance="link" link="/contact">
            Contact MRS
          </Button>
        </div>
      </Container>
    </Section>
  )
}
export default Positions
