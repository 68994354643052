import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Section } from '@components/common'
import { Button } from '@mrs-ui'

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "images/stock/h012.webp" }) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Section
      verticalSpacing="xl"
      backgroundImage={data.image.childImageSharp.fluid}
      theme="dark"
    >
      <Container>
        <div className="md:w-3/5 w-full">
          <h1>Careers at MRS</h1>
          <p>
            At MRS, your work can make a real impact for some of the most
            reputable organizations across North America.
          </p>
        </div>
        <Button className="mt-gap-default" appearance="primary" link="/contact">
          Contact us today
        </Button>
      </Container>
    </Section>
  )
}
export default Hero
