import Head from '@components/Head'
import { Hero, Highlights, Positions } from '@components/pages/careers'
import React from 'react'

const CareersPage = () => {
  return (
    <>
      <Head
        title="Tech Careers at MRS"
        description="Are you an expert in cloud technology, data analytics or software development? Browse careers at MRS."
      />
      <Hero />
      <Highlights />
      <Positions />
    </>
  )
}
export default CareersPage
