import { Container } from '@components/common'
import ContentFeature from '@components/ContentFeature'
import { Icon } from '@mrs-ui'
import React from 'react'

const Highlights = () => {
  return (
    <section className="bg-shade50  pt-gap-default">
      <Container>
        <div className="flex flex-wrap -mx-8">
          <div className="sm:w-1/3 w-full px-8 pb-6">
            <ContentFeature
              icon={<Icon name="Eye" />}
              title="Dynamic project opportunities"
              excerpt="Tech roles at MRS give you the opportunity to work on a diverse range of projects, for reputable clientele, across all industries."
            />
          </div>
          <div className="sm:w-1/3 w-full px-8 pb-6">
            <ContentFeature
              icon={<Icon name="Smile" />}
              title="Be a difference maker"
              excerpt="At MRS, we encourage everyone to be innovative and forward thinking. New ideas are not only welcomed but encouraged - and difference makers are rewarded."
            />
          </div>
          <div className="sm:w-1/3 w-full px-8 pb-6">
            <ContentFeature
              icon={<Icon name="Globe" />}
              title="Flexible employment arrangements"
              excerpt="MRS provides flexible, digital-first remote work opportunites depending on your preferences. If you do great work, we're flexible to how and where it's done."
            />
          </div>
        </div>
      </Container>
    </section>
  )
}
export default Highlights
